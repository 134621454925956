<template>
  <!-- 我的已办 -->
  <div class="concent">
    <!-- 查询表单 -->
    <base-form
      :componentList="from"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      :type="type"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData"
    >
      <div slot="btn_after">
        <base-button
          icon="iconfont iconzhankai"
          class="foldBtn"
          :class="{ PutawayBtn: fold }"
          type="default"
          @click="fold = !fold"
        ></base-button>
      </div>
    </base-form>
    <!-- 数据表格 -->
    <base-table
      :columns="columns"
      :showPage="true"
      :queryParas.sync="queryParas"
      :tableAttrs="{
        stripe: true,
      }"
      :key="type"
      ref="tableData"
      :loadCount="loadCount"
      :api="api"
      :getApi="'getPage'"
      :webPage="false"
      class="main-page-table"
      tableKey="resultList"
      totalKey="totalCount"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <!-- 按钮组 -->
        <IconButton
          @click.prevent="jump(scope.row)"
          content="办理"
          icon="iconfont iconbanli"
        />
      </template>
    </base-table>
    <select-fund-side :visible.sync='fundVisible' :fundObj='fundObj' />
  </div>
</template>
<script>
import SelectFundSide from '@/pages/business/components/select-fund-side.vue'
import { progressApi } from '@/api/businessApi'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { fromConfig, columnsConfig } from './utils/config.js'
import { myTodoApi, finBusinessInfoApi } from '@/api/workbenchApi'
import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'
import Storage from '@/utils/storage'
export default {
  name: 'todo',
  components: { BaseForm, BaseTable, IconButton, BaseButton, SelectFundSide },
  data () {
    return {
      fundObj: {},
      fundVisible: false,
      loadCount: 0,
      fold: false,
      queryParas: {
        pageSize: 10,
        pageIndex: 1,
        sort: 'CREATE_TIME_DESC',
        processDefinitionKeys: ['BPM_QZJF_FINANCE_PROCESS', 'BPM_QZJF_REPLENISH_CONTRACT']
      },
      type: 'DB'
    }
  },
  computed: {
    columns () {
      return columnsConfig(this)
    },
    from () {
      return fromConfig(this)
    },
    api () {
      return myTodoApi
    }
  },
  methods: {
    jump (row) {
      finBusinessInfoApi.getFinBusinessInfo({
        businessId: row.bizId,
        instanceId: row.instanceId || null,
        taskStatus: '1',
        actionUrl: row.actionUrl,
        taskName: row.taskName
      }).then(async resData => {
        if (!resData.data) {
          this.warning('没获取到融资业务信息')
          return
        }
        row.finBusuinessInfoVO = resData.data
        row.businessNo = resData.data.businessNo
        row.gysCompanyName = resData.data.gysCompanyName
        row.gysId = resData.data.gysId
        row.batchNum = resData.data.batchNum
        row.keyId = resData.data.keyId
        row.finaaceProductName = resData.data.finaaceProductName

        if (row.finBusuinessInfoVO && row.finBusuinessInfoVO.statusName === '评审会落实') {
          row.type = '1'
        } else if (row.finBusuinessInfoVO && row.finBusuinessInfoVO.statusName === '评审会批复') {
          row.type = '2'
        }
        if (row.actionUrl) {
          if (row.actionUrl.indexOf(';') > 0) {
            row.newActionUrl = row.actionUrl.split(';')[1]
          }
          if (row.newActionUrl.indexOf('?') > 0) {
            row.newActionUrl = row.newActionUrl.split('?')[0]
          }
          if (row.newActionUrl === '/business/auditTypeList') {
            // 判断审核任务
            const res = await financeAdmittanceApi.whetherDiscard({ businessId: row.finBusuinessInfoVO.keyId })
            if (!res.data && res.code === '0') {
              return
            }
            // 融资决议审核
            sessionStorage.setItem('examine', JSON.stringify(row))
            const params = {
              businessId: row.bizId,
              instanceId: row.instanceId,
              taskId: row.taskId
            }
            progressApi.updatestatus(params).then((res) => {
              if (res.success) {
                this.$router.push({ path: row.newActionUrl, query: { type: 'E' } })
              }
            })
          } else if (row.newActionUrl === '/business/decisionMake') {
            // 新增需求
            // 稳时付 需先选择资金方
            // 稳享付 无需选择

            if (row.finBusuinessInfoVO.finaaceProductName === '产值贷') {
              this.fundVisible = true
              this.fundObj = {
                keyId: row.finBusuinessInfoVO.keyId,
                instanceId: row.instanceId,
                capitalSideId: row.finBusuinessInfoVO.capitalSideId,
                businessNo: row.businessNo
              }
            } else {
              progressApi.checkTimeOutOrNot({ businessId: row.finBusuinessInfoVO.keyId }).then(res => {
                if (res.data.status) {
                  this.$router.push({
                    path: '/business/decisionMake',
                    query: { keyId: row.finBusuinessInfoVO.keyId, instanceId: row.instanceId }
                  })
                } else {
                  this.warning(res.data.errorMsg)
                }
              })
            }
          } else if (row.newActionUrl === '/business/contractFileMake/contractFileMake' && row.finBusuinessInfoVO && row.finBusuinessInfoVO.batchNum > 0) {
            // 合同制作补签页面
            this.$router.push({
              path: '/business/contractFileMake/contractFileMakeSignature',
              query: {
                keyId: row.bizId,
                instanceId: row.instanceId,
                businessId: row.bizId,
                businessNo: row.businessNo
              }
            })
            row.businessId = row.bizId
          } else {
            this.$router.push({
              path: row.newActionUrl,
              query: {
                keyId: row.bizId,
                instanceId: row.instanceId,
                businessId: row.bizId,
                businessNo: row.businessNo,
                type: 'deit' // 待办临时写死参数
              }
            })
            row.businessId = row.bizId
            if (row.finBusuinessInfoVO) {
              row.capitalSideProductId = row.finBusuinessInfoVO.capitalSideProductId
              row.resultValidFlag = row.finBusuinessInfoVO.resultValidFlag
              row.capitalSideName = row.finBusuinessInfoVO.capitalSideName
              row.suppliersSealType = row.finBusuinessInfoVO.suppliersSealType
            }
          }
          if (row.finBusuinessInfoVO) {
            row.capitalSideProductId = row.finBusuinessInfoVO.capitalSideProductId
            row.resultValidFlag = row.finBusuinessInfoVO.resultValidFlag
            row.capitalSideName = row.finBusuinessInfoVO.capitalSideName
            row.suppliersSealType = row.finBusuinessInfoVO.suppliersSealType
            row.batchNum = row.finBusuinessInfoVO.batchNum
          }
          Storage.setLocal('contractInfo', row)
          Storage.setSession('row', row)
          Storage.setSession('examine', row)
          Storage.setLocal('admittanceInfo', row)
        }
      })
    },
    // 重置
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1,
        sort: 'CREATE_TIME_DESC'
      }
    },
    formatTime (val) {
      if (val) {
        const time = new Date(val)
        return time.getFullYear() +
      '-' +
      (time.getMonth() + 1) +
      '-' +
      time.getDate()
      } else {
        return '--'
      }
    },
    // 查询
    handleFilter () {
      if (this.queryParas.time) {
        const timeArr = this.queryParas.time
        this.$set(this.queryParas, 'startTime', this.formatTime(timeArr[0]))
        this.$set(this.queryParas, 'endTime', this.formatTime(timeArr[1]))
      }
      this.loadCount++
    }
  },
  activated () {
    this.handleFilter()
  },
  mounted () {
    this.handleFilter()
  }
}
</script>
<style lang="scss" scoped>
.concent {
  height: calc(100% - 60px);
}
</style>
